import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';

function UploadPhoto() {

	const fileInput = useRef();
	const [url, setUrl] = useState( '' );
	const [myPhoto, setImage] = useState( '' );
	const [error, setError] = useState( '' );

	useEffect( () => {
		const origin = window.location.host;
		if ( origin.includes( 'com' ) ) {
			navigate( '/' );
		}
	}, [] );


	const onImageChange = ( event ) => {
		const maxSize = 10 * 1024 * 1024; // 10MB in bytes
		const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'application/pdf'];

		if ( event.target.files && event.target.files[0] ) {
			const file = event.target.files[0];

			// Check file type
			if ( !allowedTypes.includes( file.type ) ) {
				setError( 'Only JPEG, PNG, SVG images, and PDF files are allowed' );
				setImage( '' ); // Clear preview
				return;
			}

			// Check file size
			if ( file.size > maxSize ) {
				setError( 'File size should be less than 10MB' );
				setImage( '' ); // Clear preview
				return;
			}

			// If both checks pass, clear error and set the image preview
			setError( '' );
			setImage( URL.createObjectURL( file ) );
		}
	};


	function submit() {
		let file = fileInput.current.files[0];
		const maxSize = 10 * 1024 * 1024;

		if ( file ) {
			if ( file.size > maxSize ) {
				setError( 'File size should be less than 10MB' );
			} else {
				setError( '' ); // Clear error message
				let formData = new FormData();
				formData.append( 'image', file, file.name );

				fetch( 'https://app.getmybubble.co/api/upload', {
					method: 'POST',
					body: formData
				} )
					.then( res => res.json() )
					.then( ( { url } ) => setUrl( url ) )
					.catch( console );
			}
		}
	}

	return (
		<>
			<form method="POST" encType="multipart/form-data">
				<div className="field">
					<label htmlFor="image">Image Upload</label>

					<input
						onChange={onImageChange}
						type="file"
						accept=".png, .jpg, .jpeg, .svg, .pdf"
						ref={fileInput}
						name="image"
						id="image"
					/>
				</div>

				{error && <div style={{ color: 'red' }}>{error}</div>}

				<button type="button" onClick={submit} className="getstart-btn">Submit</button>
			</form>

			{url && <div>Uploaded to {url}</div>}

			<div>Preview:</div>

			{myPhoto && <img src={myPhoto} style={{ maxHeight: '800px', maxWidth: '600px' }} alt="preview image" />}
		</>
	);
}

export default UploadPhoto;
